import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { useInput } from "../hook/use-input"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"

const ContactFormSection = props => {
  const [checkBox, setCheckBox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [message, setMessage] = useState({
    title: "",
    text: "",
    type: "",
  })

  const {
    value: firstName,
    bind: bindFirstName,
    reset: resetFirstName,
  } = useInput("")
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("")
  const {
    value: societe,
    bind: bindSociete,
    reset: resetSociete,
  } = useInput("")
  const { value: sujet, bind: bindSujet, reset: resetSujet } = useInput("")
  const {
    value: messageMail,
    bind: bindMessageMail,
    reset: resetMessageMail,
  } = useInput("")
  const {
    value: donnees,
    bind: bindDonnees,
    reset: resetDonnees,
  } = useInput("")

  //const sendMailEndpoint = `https://visuall-sendmail.netlify.app/.netlify/functions/sendmail`
  const sendMailEndpoint = "https://visuall-sendmail.netlify.app/api/sendmail"

  const handleSubmit = (event, data) => {
    event.preventDefault()
    //alert(`Submitting Name ${firstName} ${societe}`)
    setLoading(true)

    if (!checkBox) {
      setMessage({
        title: "Utilisations des données",
        text: "vous devez accepter l'utilisation de vos données pour que nous puissions procéder à leur envoi",
        type: "error",
      })
      setLoading(false)
      return false
    }

    try {
      fetch(sendMailEndpoint, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: firstName,
          email: email,
          details: {
            societe: societe,
            sujet: sujet,
            message: messageMail,
          },
        }),
      })
        .then(res => {
          if (res.status >= 400) {
            return setMessage({
              title: "Nous sommes désolés",
              text: "Votre message n'a pas été envoyé à cause d'une erreur serveur",
              type: "error",
            })
          } else {
            setMessage({
              title: "Merci de nous avoir contacté !",
              text: "Nous reprendrons contact avec vous rapidement",
              type: "success",
            })
          }

          setLoading(false)
          //resetFirstName()
          //resetSociete()
          //resetEmail()
          //resetMessageMail()
          //resetSujet()
          //resetDonnees()
        })
        .catch(error => {
          setLoading(false)
          console.error("Error:", error)
        })
    } catch (errors) {
      console.log("Error:", errors)
    }
  }

  const handleCheckBoxChange = () => {
    setCheckBox(!checkBox)
  }

  const alertClasses = classNames(
    "fixed z-50 top-10 right-4 shadow-2xl rounded text-white py-2 pl-4 pr-10",
    {
      "bg-visuall-bleu-logo": message.type === "success",
      "bg-red-400": message.type === "error",
    }
  )

  const consentClasses = classNames("text-white", {
    "bg-red-400": donnees,
  })

  return (
    <section id="contact" className="lg:mt-12">
      <div className="container mx-auto bg-visuall-gris-logo p-4 lg:p-16 font-display">
        <div className="lg:grid lg:grid-cols-3 lg:gap-12">
          <div className="text-white">
            <span className="text-5xl font-archia">
              <span className="font-black">Parlez-nous</span> de votre projet
            </span>
            <p className="text-xl mr-24 mt-4">
              Que vous souhaitiez une offre, nous soumettre un projet ou
              simplement prendre contact, nous serons ravis d’échanger avec vous
              !
            </p>
            <StaticImage
              className="-ml-20 mt-4 hidden lg:block"
              src="../images/contact/illustration-form-home.png"
              alt={"Illustration Visuall CH"}
              quality={100}
            />
          </div>
          <div className="col-span-2 mt-5">
            <form onSubmit={handleSubmit}>
              <fieldset className="space-y-5" disabled={loading && true}>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="w-full"
                  placeholder="Nom, Prénom"
                  {...bindFirstName}
                  required
                />
                <input
                  type="text"
                  name="Societe"
                  id="Societe"
                  className="w-full"
                  placeholder="Société"
                  {...bindSociete}
                  required
                />
                <input
                  type="email"
                  name="Email"
                  id="Email"
                  className="w-full"
                  placeholder="Email"
                  {...bindEmail}
                  required
                />
                <select
                  name="Sujet"
                  id="Sujet"
                  className="w-full"
                  {...bindSujet}
                >
                  <option value="0" defaultValue>
                    Quel est le sujet de votre demande ?
                  </option>
                  <option value="1">Un projet web ou digital</option>
                  <option value="2">Un projet vidéo</option>
                  <option value="3">
                    Un projet d’identité visuelle ou print
                  </option>
                  <option value="4">Autre</option>
                </select>
                <textarea
                  name="Message"
                  id="Message"
                  cols="30"
                  rows="10"
                  className="w-full"
                  placeholder="Votre message"
                  {...bindMessageMail}
                  required
                />
                <label htmlFor="consent" className="text-white">
                  <input
                    type="checkbox"
                    name="consent"
                    id="consent"
                    className="mr-5"
                    onChange={handleCheckBoxChange}
                    value={checkBox}
                  />
                  En soumettant ce formulaire, j’accepte que les informations
                  saisies soient exploitées dans le cadre de ma demande et de la
                  relation professionnelle qui peut en découler.
                </label>
                <p className="text-white text-sm opacity-90">
                  <strong>Nous respectons vos données.</strong> Les informations
                  recueillies sur ce formulaire sont enregistrées par Visuall
                  Communication SARL dans un fichier informatisé. Visuall
                  Communication SARL respecte votre vie privée et s’engage à ne
                  traiter vos données que dans le cadre de la finalité de ce
                  formulaire : répondre à votre requête et gérer vos données de
                  contact. Vos données sont conservées pendant 2 ans et sont
                  destinées au service commercial et au service client. Vous
                  pouvez exercer votre droit d’accès aux données vous concernant
                  et les faire rectifier en contactant notre service commercial
                  au +41 22 706 27 50. Consultez également nos mentions légales
                  accessibles en bas de cette page pour plus de renseignements.
                </p>
                <button
                  type="submit"
                  className="flex justify-center items-center uppercase cursor-pointer text-white bg-visuall-bleu-logo px-8 py-2"
                >
                  {loading && (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      en cours
                    </>
                  )}
                  {!loading && <>Envoyer</>}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {message.title && message.text && (
          <>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className={alertClasses}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-2 right-2 h-6 w-6 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onClick={() => setMessage(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p>{message.title}</p>
              <p>{message.text}</p>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </section>
  )
}

ContactFormSection.propTypes = {}

export default ContactFormSection
